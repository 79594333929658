<template>
  <div class="with-nav-bar">
    <van-nav-bar
      title="附近充电站"
      :left-text="state.canReturn ? '返回' : '首页'"
      :left-arrow="true"
      :fixed="true"
      @click-left="
        state.canReturn ? $router.back() : $router.push({ name: 'Home' })
      "
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <van-index-bar v-else-if="state.items.length > 0" :index-list="[]">
      <div v-for="(item, index) in state.items" :key="index">
        <van-index-anchor :index="item.city" />
        <van-cell v-if="!item.stations.length">
          <template #value>
            <span class="text-secondary">附近十公里内无可用充电站</span>
          </template>
        </van-cell>
        <div v-else>
          <van-cell
            v-for="station in item.stations"
            :key="station.id"
            :label="station.address"
            is-link
            center
            @click="openmap(station)"
          >
            <template #title>
              {{ `${station.name} ${station.distance}公里` }}
            </template>
            <template #right-icon>
              <van-tag
                :type="station.available_gun > 0 ? 'success' : 'danger'"
                plain
                >可用 {{ station.available_gun }}</van-tag
              >
            </template>
          </van-cell>
        </div>
      </div>
    </van-index-bar>
    <van-empty v-else image="network" :description="state.emptymsg" />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { onMounted, reactive } from "vue";
import { List, Tag, Toast, IndexBar, IndexAnchor } from "vant";
import { signaturePack } from "@/api/wechat.service";
import { searchNearby } from "@/api/station.service";
import { useRoute } from "vue-router";
export default {
  components: {
    [Tag.name]: Tag,
    [List.name]: List,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
  },

  setup() {
    const route = useRoute();
    const state = reactive({
      items: [],
      loading: true,
      latitude: 0,
      longitude: 0,
      canReturn: false,
      emptymsg: "附近十公里内无可用充电站",
    });

    const loadPage = async () => {
      if (!state.latitude || !state.longitude) {
        state.loading = false;
        return;
      }
      try {
        const { result } = await searchNearby({
          latitude: state.latitude,
          longitude: state.longitude,
        });
        state.items.push(...result.items);
        state.loading = false;
      } catch (error) {
        state.loading = false;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const signaturePage = async () => {
      try {
        const { result } = await signaturePack(location.href.split("#")[0]);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "getLocation", "openLocation"],
        });
        wx.error((res) => {
          Toast(res.errMsg);
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({
          message: msg ? msg : "前端脚本异常/页面签名失败",
          position: "bottom",
        });
        if (!msg) console.log(error);
      }
    };

    const openmap = (station) => {
      wx.openLocation({
        latitude: station.latitude,
        longitude: station.longitude,
        name: station.name,
        address: station.address,
        scale: 14, // 地图缩放级别,整型值,范围从1~28, 默认为最大
        infoUrl: "", // 在查看位置界面底部显示的超链接, 可点击跳转
      });
    };

    onMounted(() => {
      const { ref } = route.query;
      state.canReturn = ref ? true : false;
      signaturePage();
      wx.ready(() => {
        wx.hideOptionMenu();
        wx.getLocation({
          type: "gcj02",
          success: function (res) {
            const { latitude, longitude } = res;
            state.latitude = latitude;
            state.longitude = longitude;
          },
          cancel: function () {
            Toast("已取消");
            state.emptymsg = "请在进入本页面时允许定位";
          },
          complete: function () {
            loadPage();
          },
        });
      });
    });

    return { state, openmap, loadPage };
  },
};
</script>
