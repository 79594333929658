import ApiService from "./api.service";

export function loadStation(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/getById", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function saveStation(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/save", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getStationList(page, agent_name = "") {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/listByAgent", { page, agent_name })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getSimpleStationList(agent_name = "") {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/simpleListByAgent", { agent_name })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getFeeSettings(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/getFeeSettings", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function updateFeeSettings(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/updateFeeSettings", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getDevicesFeeStatus(id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/getDevicesFeeStatus", { id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function searchNearby(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/searchNearby", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getProfitSharing(station_id) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/getProfitSharing", { station_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function setProfitSharing(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/common/station/setProfitSharing", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
